import React, { Component, Fragment } from 'react'
import Input from '../../components/Input'
import { Link } from 'react-router-dom'
import Card from './card'
import { Ace, AddButton, Search } from '../../components'
import { connect } from 'react-redux'
import {
  addNewCollection,
  changeCollections,
  removeCollection,
  requestCollections,
  saveCollections, updateCollections,
} from '../../action/collections'
import axios from 'axios'
import { InferLabelFromName } from '../../utils'
import { GLOBAL } from '../../config'
import { toast } from 'react-toastify'
import update from 'immutability-helper'
import styled from 'styled-components'
import Version from './version'
import Settings from './settings'
import NavButtonsOld from '../../components/NavButtonsOld'

import ModalAdd from './modal/add'
import Globals from './globals'
import { produce } from 'immer'
import Synchronize from './modal/synchronize'
import getIsProduct from '../../utils/isProduct'

const enforceMaximums = false

const TemplateWrapper = styled.div`
    height: auto !important;
`

class Collections extends Component {
  state = {
    snapshots: [],
    active: 0,
    isActive: false,
    temporary: [],
    groups: [],
    isModal: false,
    isSynchronize: false,
    search: '',
  }

  async componentDidMount () {
    const { request } = this.props
    request()
    try {
      const { data } = await axios.get('/api/groups')
      this.setState({ groups: data })
      this.fetchSnapshots(0)
    } catch (e) {
      console.log(e)
    }
  }

  fetchSnapshots = async (active) => {
    const { collections } = this.props
    if (collections.length === 0) {
      setTimeout(() => this.fetchSnapshots(active), 500)
      return
    }
    if (active >= 0) {
      const catalog = collections[active]
      if (catalog) {
        const catalogName = catalog && catalog.name
        if (catalogName) {
          const { data } = await axios.get(
            `/api/collections/${catalogName}/snapshots`)
          if (data && data.status === 'Done' && Array.isArray(data.Snapshots)) {
            this.setState({
              snapshots: [...data.Snapshots],
            })
          }
        }
      }
    }
  }

  handleActive = active => {
    this.setState({
      active,
    })
    this.fetchSnapshots(active)
  }

  getCollection = () => this.props.collections[this.state.active]

  checkLength = () => {
    if (this.props.collections.length < 6 || !enforceMaximums) {
      this.setState({
        isModal: true,
      })
    } else {
      toast.error(
        'You have reached the maximum number of collections (6) and must remove an existing collection ' +
        'before you can create a new one. (This limit will be extended in the future.)',
        {
          position: 'top-center',
          autoClose: false,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        },
      )
    }
  }

  setExternalAccess = (appName, access) => {
    const { active } = this.state
    const appOptions = this.getCollection().appOptions || []
    const newAppOptions = [...appOptions]
    const index = newAppOptions.findIndex(ao => ao.appName === appName)
    if (index >= 0) {
      const newOpt = { ...newAppOptions[index] }
      newOpt.extAccess = access
      newAppOptions[index] = newOpt
    } else {
      newAppOptions.push({
        appName,
        extAccess: access,
      })
    }
    this.onChange('appOptions', newAppOptions, active)
  }

  setNotification = (appName, notification) => {
    const { active } = this.state
    const appOptions = this.getCollection().appOptions || []
    const newAppOptions = [...appOptions]
    const index = newAppOptions.findIndex(ao => ao.appName === appName)
    if (index >= 0) {
      newAppOptions[index] = {
        ...newAppOptions[index],
        notification,
      }
    } else {
      newAppOptions.push({
        appName,
        notification,
      })
    }
    this.onChange('appOptions', newAppOptions, active)
  }

  setSendDocuments = (appName, isSendDocuments) => {
    const { active } = this.state
    const appOptions = this.getCollection().appOptions || []
    const newAppOptions = [...appOptions]
    const index = newAppOptions.findIndex(ao => ao.appName === appName)
    if (index >= 0) {
      newAppOptions[index] = {
        ...newAppOptions[index],
        isSendDocuments,
      }
    } else {
      newAppOptions.push({
        appName,
        isSendDocuments,
      })
    }
    this.onChange('appOptions', newAppOptions, active)
  }

  setUploadDocuments = (appName, uploadDocuments) => {
    const { active } = this.state
    const appOptions = this.getCollection().appOptions || []
    const newAppOptions = [...appOptions]
    const index = newAppOptions.findIndex(ao => ao.appName === appName)
    if (index >= 0) {
      newAppOptions[index] = {
        ...newAppOptions[index],
        uploadDocuments,
      }
    } else {
      newAppOptions.push({
        appName,
        uploadDocuments,
      })
    }
    this.onChange('appOptions', newAppOptions, active)
  }

  saveVersion = async () => {
    const { collections } = this.props
    const {
      active,
      environment,
    } = this.state
    const collection = collections[active]
    const id = collection ? collection.type ? collection.type._id
      ? collection.type._id
      : undefined : undefined : undefined
    const { data } = await axios.put(`/api/types/${id}/environment/`,
      { activesSnapshotsForApps: environment.activesSnapshotsForApps })
    if (data.environment) {
      this.setState({
        isActive: false,
        environment: data.environment,
      })
    }
  }

  changeVersion = appName => id => {
    this.changeAppOption(appName, 'liveVersion', id)
  }

  changeIsDisabled = appName => value => {
    this.changeAppOption(appName, 'isDisabled', value)
  }

  changeAppOption = (appName, optName, value) => {
    const {
      collections,
    } = this.props
    const { active } = this.state
    const collection = collections[active]
    const allAppOptions = (collection && collection.appOptions) || []
    const appOptions = allAppOptions.filter(ao => !ao.globalTarget)
    const type = collection && collection.type
    const appsFromType = (type && type.apps) || []
    const newAppOptions = appsFromType.map(app => {
      const existingAppOptions = appOptions.find(
        _app => _app.appName === app.name)
      return existingAppOptions || {
        appName: app.name,
        liveVersion: '',
        extAccess: false,
        isDisabled: false,
        notification: '',
      }
    })
    const index = newAppOptions.findIndex(
      ao => ao.appName === appName)
    if (index >= 0) {
      const newOpt = { ...newAppOptions[index] }
      newOpt[optName] = value
      newAppOptions[index] = newOpt
    } else {
      newAppOptions.push({
        appName,
        [optName]: value,
      })
    }
    newAppOptions.push(...allAppOptions.filter(ao => ao.globalTarget))
    this.onChange('appOptions', newAppOptions, active)
  }

  changeGlobalAppOption = (Option) => {
    console.log('Option', Option)
    const {
      collections,
    } = this.props
    const { active } = this.state
    const collection = collections[active]
    let globalIndex
    const type = collection && collection.type
    if (type && type.properties) {
      globalIndex = {}
      type.properties.filter(p => p.type === GLOBAL).
        forEach(p => globalIndex[p.name] = p)
    }
    const appOptions = (collection && collection.appOptions) || []

    const newAppOptions = appOptions.filter(ao => !ao.globalTarget)
    const globalOptIndex = {}
    appOptions.filter(ao => Boolean(ao.globalTarget)).forEach(ao => {
      // also filter out app options for non-existing global variables
      if (!globalIndex || (ao.globalTarget in globalIndex)) {
        globalOptIndex[ao.globalTarget] = ao
      }
    })
    globalOptIndex[Option.globalTarget] = Option
    newAppOptions.push(...Object.values(globalOptIndex))
    this.onChange('appOptions', newAppOptions, active)
  }

  saveCollection = () => {
    this.props.save(this.state.active)
    this.setState({ isActive: false })
    const collections = this.props.collections
    const currentCollection = collections[this.state.active]
    if (currentCollection) {
      const assortment = collections.filter(
        collection => collection.type._id === currentCollection.type._id)
      if (assortment.length > 1) {
        this.setState({ isSynchronize: true })
      }
    }
  }

  moveUp = index => {
    const {
      collections,
      updateCollections,
    } = this.props
    const nextCollection = collections[index]
    const nextCollections = update(collections, {
      $splice: [[index, 1], [index - 1, 0, nextCollection]],
    })
    updateCollections(nextCollections.map((collection, index) => ({
      ...collection,
      position: index,
    })))
    this.setState({ isActive: true })
  }

  updateSnapshot = (id, label) => {
    this.setState({
      snapshots: produce(this.state.snapshots, draft => {
        const index = draft.findIndex(todo => todo._id === id)
        if (index !== -1) {
          draft[index].label = label
        }
      }),
    })
  }

  moveDown = index => {
    const {
      collections,
      updateCollections,
    } = this.props
    const nextCollection = collections[index]
    const nextCollections = update(collections, {
      $splice: [[index, 1], [index + 1, 0, nextCollection]],
    })
    updateCollections(nextCollections.map((collection, index) => ({
      ...collection,
      position: index,
    })))
    this.setState({ isActive: true })
  }

  onChange = (key, val, index) => {
    if (this.state.search) {
      const collectionsWithSearch = this.props.collections.filter(
        collection => {
          return collection.name.toLowerCase().
            includes(this.state.search.toLowerCase())
        })
      const currentCollection = collectionsWithSearch[index]
      if (currentCollection) {
        index = this.props.collections.findIndex(
          _c => _c._id === currentCollection._id)
      }
    }

    if (!this.state.isActive) {
      this.setState({ isActive: true })
    }
    this.props.change(key, val, index)
  }

  onCancel = () => this.setState({ isModal: false })
  onCancelSynchronize = () => this.setState({ isSynchronize: false })

  onSave = (collection) => {
    this.props.add(collection)
    this.onCancel()
  }

  onSearch = (search) => {
    this.setState({ search })
  }

  render () {
    const {
      collections,
      settings,
      remove,
    } = this.props
    const {
      active,
      snapshots,
      isActive,
      isModal,
      groups,
      isSynchronize,
      search,
    } = this.state

    let collectionsWithSearch = collections
    if (search) {
      collectionsWithSearch = collections.filter(collection => {
        return collection.name.toLowerCase().includes(search.toLowerCase())
      })
    }

    const collection = collectionsWithSearch[active]
    const type = collection && collection.type
    const apps = type ? type.apps ? type.apps : [] : []

    return (
      <Fragment>
        <div className={'row'}>
          <div className={'col-2 vh-75 overflow-auto'}>
            DATA CATALOGS
            <div className={'mt-2'}/>
            <Search onSubmit={this.onSearch}/>
            <div style={{ height: '78vh', overflow: 'auto' }}>
              {collectionsWithSearch.map((collection, index) => (
                <Card
                  key={collection._id} name={collection.name}
                  collection={collection} index={index}
                  countCollections={collections.length}
                  moveUp={this.moveUp}
                  moveDown={this.moveDown}
                  active={active}
                  setActive={this.handleActive}
                  remove={remove}/>
              ))}
            </div>
            <div className={'w-100 mt-2'}>
              <AddButton label={'Add a new catalog'} id={'addNewCollection'}
                         onClick={this.checkLength}/>
            </div>
          </div>
          {collection && !getIsProduct(collection.type) &&
            <div className={'col-10'}>
              DETAILS
              <div
                className={'w-100 p-4 mt-2 knackly-plain border border-dark'}>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <Input label={'CATALOG NAME'} value={collection.name}/>
                  </div>
                  <div className={'col-3'}>
                    <Input label={'LABEL (PLURAL)'}
                           value={collection.label}
                           placeholder={InferLabelFromName(collection.name)}
                           className={'form-control rounded-0'}
                           onChange={val => {
                             this.onChange('label', val, active)
                           }}
                    />
                  </div>
                  <div className={'col-3'}>
                    <label>CATALOG ITEM TYPE</label>
                    <div className={'form-control rounded-0 br-gray-50 '}>{type
                      ? <Link
                        to={`/${window.tenancy}/types/${type.name}`}
                        className={'text-black-50 link-knackly'}>{type.name}</Link>
                      : 'empty'}</div>
                  </div>
                  <div className={'col-3'}>
                    <NavButtonsOld
                      isActive={isActive}
                      save={this.saveCollection}
                      saveCaption={'Save changes to collections'}
                      cancel={console.log}
                      cancelCaption={'Revert changes'}
                    />
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <label className={'mb-1'}>COLUMNS</label>
                    <div className="form-group form-check mb-1">
                      <input type="checkbox" className="form-check-input"
                             checked={true}/>
                      <label className="form-check-label">Item Summary
                        template</label>
                    </div>
                    <div className="form-group form-check mb-1">
                      <input type="checkbox" className="form-check-input"
                             checked={collection.lastModified}/>
                      <label className="form-check-label">Last Modified
                        date</label>
                    </div>
                    <div className="form-group form-check mb-1">
                      <input type="checkbox" className="form-check-input"
                             checked={false} disabled={true}/>
                      <label className="form-check-label">Created By</label>
                    </div>
                    <label className={'mb-1 mt-2'}>DEFAULT FILTERS</label>
                    <div className="form-group form-check mb-1">
                      <input type="checkbox" id={'myRecords'}
                             className="form-check-input cursorPointer"
                             checked={collection.myRecords || false}
                             onChange={() => this.onChange('myRecords',
                               !collection.myRecords, active)}/>
                      <label className="form-check-label cursorPointer"
                             for={'myRecords'}>My records</label>
                    </div>
                  </div>
                  <div className={'col-6'}>
                    <label htmlFor={'itemDetails'}>ITEM DETAIL TEMPLATE</label>
                    <TemplateWrapper
                      className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3'}>
                      <Ace value={collection.detail}
                           className="border-0"
                           maxLines={Infinity}
                           onChange={val => this.onChange('detail', val,
                             active)}/>
                    </TemplateWrapper>
                    <div className={'row'}>
                      <div className="col-8">
                        <label htmlFor={'itemDetails'}>CREATE RECORD
                          LABEL</label>
                        <TemplateWrapper
                          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3'}>
                          <Ace value={collection.recordLabel}
                               className="border-0"
                               maxLines={Infinity}
                               placeholder={'Create record'}
                               onChange={val => this.onChange('recordLabel',
                                 val,
                                 active)}/>
                        </TemplateWrapper>
                      </div>
                      <div className="col-8">
                        <label htmlFor={'itemDetails'}>GLOBAL INFO LABEL</label>
                        <TemplateWrapper
                          className={'knackly-plain rounded-0 py-2 px-2 form-control mb-3'}>
                          <Ace value={collection.globalLabel}
                               className="border-0"
                               maxLines={Infinity}
                               placeholder={'Global Info'}
                               onChange={val => this.onChange('globalLabel',
                                 val,
                                 active)}/>
                        </TemplateWrapper>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={'row'}>
                  <div className={'col-3'}>
                    <label>APPS ON THIS CATALOG</label>
                  </div>
                  <div className={'col-1'}></div>
                  <div className={'col-3'}>
                    <label>&quot;LIVE&quot; VERSION</label>
                  </div>
                  {(
                    <div className={'col-5'}>
                      <label>APP SETTINGS (PER CATALOG)</label>
                    </div>
                  )}
                </div>

                {apps.map(app => {
                  const appOpt = collection.appOptions.find(
                    ao => ao.appName === app.name) || {}
                  const appUrl = app.extUsers && appOpt.extAccess &&
                    (window.location.origin + '/' + window.tenancy + '/' +
                      encodeURIComponent(collection.name) + '/' +
                      encodeURIComponent(app.name)) + '?external'
                  const liveVersion = appOpt ? appOpt.liveVersion
                    ? appOpt.liveVersion
                    : undefined : undefined
                  const isDisabled = appOpt ? appOpt.isDisabled
                    ? appOpt.isDisabled
                    : false : false
                  return (
                    <div key={collection.name + app.name} className={'row'}>
                      <div className={'col-3'}>
                        <label style={{ fontWeight: 'bold' }}>{app.label ||
                          app.name}</label>
                      </div>
                      <Version
                        changeVersion={this.changeVersion(app.name)}
                        liveVersion={liveVersion}
                        isDisabled={isDisabled}
                        updateSnapshot={this.updateSnapshot}
                        catalogName={collection && collection.name}
                        changeIsDisabled={this.changeIsDisabled(app.name)}
                        snapshots={snapshots.filter(
                          snapshot => snapshot.AppID === app.name).sort(
                          (a, b) => new Date(b.version) - new Date(a.version))}
                      />
                      <Settings settings={settings}
                                app={app}
                                appUrl={appUrl}
                                appOpt={appOpt}
                                setExternalAccess={this.setExternalAccess}
                                setNotification={this.setNotification}
                                setSendDocuments={this.setSendDocuments}
                                setUploadDocuments={this.setUploadDocuments}
                                groups={groups}
                      />
                    </div>
                  )
                })}
                {type && type.properties.some(el => el.type === GLOBAL) &&
                  (<Fragment>
                    <div className={'row'}>
                      <div className={'col-12 mb-2'}>GLOBAL INFO ON THIS CATALOG
                      </div>
                      <div className={'col-3'}>
                        <div className={'row'}>
                          <div className={'col-6'}><label>Name</label></div>
                          <div className={'col-6'}><label>Edit using
                            app...</label>
                          </div>
                        </div>
                      </div>
                      <div className={'col-3'}>
                        <label>&quot;LIVE&quot; VERSION</label>
                      </div>
                      <div className={'col-6'}/>
                    </div>
                    <Globals type={type}
                             snapshots={snapshots}
                             updateSnapshot={this.updateSnapshot}
                             catalogName={collection && collection.name}
                             appOptions={collection.appOptions}
                             onChange={this.changeGlobalAppOption}
                    />
                  </Fragment>)}
              </div>
            </div>}
        </div>
        <ModalAdd
          visible={isModal}
          onSave={this.onSave}
          onCancel={this.onCancel}
          collections={collections}
        />
        {(isSynchronize) &&
          (<Synchronize visible={isSynchronize}
                        catalog={collection}
                        model={type}
                        collections={collections}
                        onCancel={this.onCancelSynchronize}/>)}
      </Fragment>
    )
  }
}

const mapStateToProps = state => {
  const {
    collections,
    settings,
  } = state
  return {
    collections,
    settings,
  }
}

const mapDispatchToProps = dispatch => ({
  change: (key, val, index) => {
    console.log('change', key, val, index)
    console.log(typeof val)
    dispatch(changeCollections(key, val, index))
  },
  save: () => {
    dispatch(saveCollections())
  },
  remove: (_id) => {
    dispatch(removeCollection(_id))
  },
  request: () => {
    dispatch(requestCollections())
  },
  updateCollections: collections => {
    dispatch(updateCollections(collections))
  },
  add: ({
    name,
    type,
    isEveryone,
  }) => {
    dispatch(addNewCollection({
      name,
      type,
      isEveryone,
    }))
  },
})

export default connect(mapStateToProps, mapDispatchToProps)(Collections)
