import React, { useEffect, useMemo } from 'react'
import {
  RecoilRoot,
  useRecoilState,
} from 'recoil'

import Icon from '../../components/Icon'
import Navigation from './navigation'
import { SettingsTypesSelector } from './state'
import DetailsComponent from './details'

const TypesComponent = ({
  isClio,
  isFilevine,
}) => {

  const [settings, setSettings] = useRecoilState(SettingsTypesSelector)

  useEffect(() => {
    const isOpenListModels = localStorage.getItem('isOpenListModels')
    const isOpenRightPanel = localStorage.getItem('isOpenRightPanel')
    const is3dModals = localStorage.getItem('is3dModals')

    const nextSettings = { ...settings }
    let needUpdateSettings = false
    if (isOpenListModels) {
      const nextIsOpenListModels = !!parseInt(isOpenListModels)
      if (nextSettings.isOpenListModels !== nextIsOpenListModels) {
        nextSettings.isOpenListModels = nextIsOpenListModels
        needUpdateSettings = true
      }
    }
    if (isOpenRightPanel) {
      const nextIsOpenRightPanel = !!parseInt(isOpenRightPanel)
      if (nextSettings.isOpenRightPanel !== nextIsOpenRightPanel) {
        nextSettings.isOpenRightPanel = nextIsOpenRightPanel
        needUpdateSettings = true
      }
    }
    if (is3dModals) {
      const nextIs3dModals = !!parseInt(is3dModals)
      if (nextSettings.is3dModals !== nextIs3dModals) {
        nextSettings.is3dModals = nextIs3dModals
        needUpdateSettings = true
      }
    }
    if (nextSettings.isClio !== isClio) {
      nextSettings.isClio = isClio
      needUpdateSettings = true
    }
    if (nextSettings.isFilevine !== isFilevine) {
      nextSettings.isFilevine = isFilevine
      needUpdateSettings = true
    }

    if (needUpdateSettings) {
      setSettings(nextSettings)
    }

  }, [
    isClio,
    isFilevine])

  const MemoNavigation = useMemo(() => (<Navigation/>), [])

  const updateSettings = (key) => {
    localStorage.setItem(key, settings[key] ? '0' : '1')
    setSettings(prevSettings => {
      return {
        ...prevSettings,
        [key]: !prevSettings[key],
      }
    })
  }

  return (<div className={settings.isOpenListModels ? '' : 'd-flex'}>
    {!settings.isOpenListModels && <div className={'mr-3'}>
      <Icon type="icon-return"
            onClick={() =>
              updateSettings('isOpenListModels')
            }
            style={{
              fontSize: '1.3rem',
              verticalAlign: '0.1em',
              transform: 'scale(-1, 1)',
            }}/>
    </div>}
    <div className={settings.isOpenListModels
      ? 'row flex-fill'
      : 'row ml-0 flex-fill'}>
      {settings.isOpenListModels && MemoNavigation}
      <React.Suspense fallback={<div>Loading...</div>}>
        <DetailsComponent/>
      </React.Suspense>
    </div>
  </div>)
}

const WrapRecoil = (props) => {
  return <RecoilRoot>
    <TypesComponent {...props} />
  </RecoilRoot>
}
export default WrapRecoil
