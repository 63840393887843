import React from 'react'
import RightPanel from '../RightPanel'
import { useRecoilState, useRecoilValue } from 'recoil'
import { CurrentTypeSelector, SettingsTypesSelector } from '../state'
import Icon from '../../../components/Icon'
import Type from '../type'
import getIsProduct from '../../../utils/isProduct'

const DetailsComponent = () => {
  const [settings, setSettings] = useRecoilState(SettingsTypesSelector)
  const current = useRecoilValue(CurrentTypeSelector)
  const updateSettings = (key) => {
    localStorage.setItem(key, settings[key] ? '0' : '1')
    setSettings(prevSettings => {
      return {
        ...prevSettings,
        [key]: !prevSettings[key],
      }
    })
  }
  if (getIsProduct(current)) {
    return null
  }
  return (<div className={settings.isOpenListModels ? 'col-10' : 'col-12'}>
    <div className={'row'}>
      <div className={settings.isOpenRightPanel ? 'col-11' : 'col-9'}>
        <label className="mb-2">DETAILS</label>
      </div>
      <div className={settings.isOpenRightPanel ? 'col-1 text-right' : 'col-3'}>
        <Icon type="icon-return"
              onClick={() => updateSettings('isOpenRightPanel')}
              style={{
                fontSize: '1.3rem',
                verticalAlign: '0.1em',
                transform: settings.isOpenRightPanel
                  ? 'scale(1, 1)'
                  : 'scale(-1, 1)',
              }} />
      </div>
    </div>
    <div className={'row knackly-plain pt-2'}>
      <div className={settings.isOpenRightPanel ? 'col-12' : 'col-9'}>
        <div className={'row'}>
          <React.Suspense fallback={<div>Loading...</div>}>
            <Type />
          </React.Suspense>
        </div>
      </div>
      {!settings.isOpenRightPanel && (
        <React.Suspense fallback={<div>Loading...</div>}>
          <RightPanel />
        </React.Suspense>)}
    </div>

  </div>)
}

export default DetailsComponent
