import {
  atom,
  selector,
  selectorFamily,
} from 'recoil'
import axios from 'axios'
import sortTypes from '../../utils/sortTypes'
import { matchPath } from 'react-router'

import { SearchAtom } from './navigation/state'
import { produce } from 'immer'
import _ from 'lodash'
import { queryClient } from '../../config'

export const SettingsTypesAtom = atom({
  key: 'SettingsTypesAtom',
  default: {
    isOpenListModels: true,
    isOpenRightPanel: true,
    is3dModals: false,
    isClio: false,
    isFilevine: false,
  },
})
export const SelectedMembersAtom = atom({
  key: 'SelectedMembersAtom',
  default: {},
})

export const SettingsTypesSelector = selector({
  key: 'SettingsTypesSelector',
  get: ({
    get,
  }) => {
    const settings = get(SettingsTypesAtom)
    return settings
  },
  set: ({
    set,
  }, newValue) => {
    set(SettingsTypesAtom, newValue)
  },
})

export const TypesAtom = atom({
  key: 'Types',
  default: [],
})

export const TypesSelector = selector({
  key: 'TypesSelector',
  get: async ({ get }) => {
    const Types = get(TypesAtom)
    if (Types.length) {
      return Types
    }

    return await queryClient.fetchQuery('/api/v2/types', async () => {
      let params = {
        fields: 'name,kind,type,_id,isProduct',
      }
      const { data } = await axios.get('/api/v2/types', {
        params,
      })
      if (data) {
        return sortTypes(data.map(item => {
          item.neededLoading = true
          return item
        }))
      }
      return []
    })
  },
  set: ({
    get,
    set,
  }, newValue) => {
    const search = get(SearchAtom)
    if (search) {
      set(SearchAtom, '')
    }
    set(TypesAtom, newValue)
  },
})

export const getTypeName = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:tenancy/types/:typeName',
  })
  if (match && match.params && match.params.typeName) {
    return decodeURI(match.params.typeName)
  }
  return null
}
export const getMemberKey = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:tenancy/types/:typeName/:tab',
  })
  if (match && match.params && match.params.tab) {
    if (match.params.tab === 'variables') {
      return 'properties'
    }
    return match.params.tab
  }
  return 'properties'
}
export const getCurrentTab = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:tenancy/types/:typeName/:tab',
  })
  if (match && match.params && match.params.tab) {
    return match.params.tab
  }
  return 'variables'
}

export const getMemberName = () => {
  const match = matchPath(window.location.pathname, {
    path: '/:tenancy/types/:typeName/:tab/:memberName',
  })
  if (match && match.params && match.params.memberName) {
    return decodeURI(match.params.memberName)
  }
  return null
}

export const CurrentTypeSelector = selector({
  key: 'CurrentTypeSelector',
  get: async ({ get }) => {
    const types = get(TypesSelector)
    const typeName = getTypeName()
    let currentType = typeName
      ? types.find(type => type.name === typeName)
      : types[0]
    if (currentType) {
      const type = await queryClient.fetchQuery(
        `/api/v2/types/${currentType.kind}/${currentType._id}`, async () => {
          const { data } = await axios.get(
            `/api/v2/types/${currentType.kind}/${currentType._id}`)
          return data
        })
      return ({ ...type })
    }
    return null
  },
})

export const CurrentMembersSelector = selector({
  key: 'CurrentMembersSelector',
  get: async ({ get }) => {
    let currentType = get(CurrentTypeSelector)
    if (currentType) {
      const memberKey = getMemberKey()
      const elements = get(ElementsChangesAtom)
      const newMembers = elements.filter(
        element => element.action === 'add' && element.parent ===
          currentType._id && element.kind === getKind(memberKey))

      const Members = currentType[memberKey] || []
      let members = produce(Members, draft => {
        draft.push(...newMembers)
      })

      members = produce(members, draft => {
        const haveAlreadyRemote = elements.find(
          element => element.action === 'remove')
        if (haveAlreadyRemote) {
          const needRemove = elements.filter(element =>
            element.action === 'remove'
            && element.parent === currentType._id
            && element.kind === getKind(memberKey)).
            reduce((previousValue, currentValue) => ({
              ...previousValue,
              [currentValue._id]: true,
            }), {})
          return draft.filter(element => !(element._id in needRemove))
        }
      })

      return members
    }
    return []
  },
})

export const CurrentMembersByKeySelector = selectorFamily({
  key: 'CurrentMembersByKeySelector',
  get: key => async ({ get }) => {
    let currentType = get(CurrentTypeSelector)
    if (currentType) {
      const memberKey = key
      const elements = get(ElementsChangesAtom)
      const newMembers = elements.filter(
        element => element.action === 'add' && element.parent ===
          currentType._id && element.kind === getKind(memberKey))

      const Members = currentType[memberKey] || []
      let members = produce(Members, draft => {
        draft.push(...newMembers)
      })

      members = produce(members, draft => {
        const haveAlreadyRemote = elements.find(
          element => element.action === 'remove')
        if (haveAlreadyRemote) {
          const needRemove = elements.filter(element =>
            element.action === 'remove'
            && element.parent === currentType._id
            && element.kind === getKind(memberKey)).
            reduce((previousValue, currentValue) => ({
              ...previousValue,
              [currentValue._id]: true,
            }), {})
          return draft.filter(element => !(element._id in needRemove))
        }
      })

      return members
    }
    return []
  },
})

export const CurrentMemberSelector = selector({
  key: 'CurrentMemberSelector',
  get: async ({ get }) => {

    let currentType = get(CurrentTypeSelector)
    const memberKey = getMemberKey()
    const memberName = getMemberName()

    if (currentType) {
      let Members = currentType[memberKey] || []

      if (Members.length) {
        Members = _.orderBy(Members, [member => member.name.toLowerCase()],
          ['asc'])
      }

      if (!memberName) {
        if (Members[0]) {
          return Members[0]
        }
      }
      const member = Members.find(Member => Member.name === memberName)
      if (member) {
        return member
      }
      const elements = get(ElementsChangesAtom)
      if (!memberName) {
        const draftMember = elements.find(element => element.action === 'add'
          && element.parent === currentType._id
          && element.kind === getKind(memberKey))
        if (draftMember) {
          return draftMember
        }
      }
      const newMember = elements.find(element => element.action === 'add'
        && element.parent === currentType._id
        && element.kind === getKind(memberKey)
        && element.name === memberName)

      if (newMember) {
        return newMember
      }
    }
    return null
  },
})

export const getKind = (tab) => {
  if (tab === 'properties') {
    return 'variable'
  }
  if (tab === 'templates') {
    return 'template'
  }
  if (tab === 'formulas') {
    return 'formula'
  }
  if (tab === 'apps') {
    return 'app'
  }
  if (tab === 'layouts') {
    return 'layout'
  }
  return 'variable'
}

export const editTypeProperty = selectorFamily({
  key: 'editTypeProperty',
  get: (path) => ({ get }) => {
    const type = get(CurrentTypeSelector)
    const elements = get(ElementsChangesAtom)
    const currentElement = elements.find(element => element._id === type._id)
    if (currentElement) {
      if (path in currentElement) {
        return _.get(currentElement, path)
      }
    }
    return _.get(type, path, '')
  },
  set: (path) => ({
    get,
    set,
  }, newValue) => {
    const elements = get(ElementsChangesAtom)
    const type = get(CurrentTypeSelector)
    const nextElements = produce(elements, (draft) => {
      const currentElement = draft.findIndex(el => el._id === type._id)
      if (currentElement === -1) {
        draft.push({
          action: 'update',
          _id: type._id,
          kind: type.kind,
          [path]: newValue,
        })
        return
      }
      _.set(draft[currentElement], path, newValue)
    })
    window.typesWereChanged = true
    set(ElementsChangesAtom, nextElements)
  },
})

export const globalListsCurrentType = selector({
  key: 'globalListsCurrentType',
  get: async ({ get }) => {
    const typeName = getTypeName()
    const members = get(CurrentMembersByKeySelector('properties')) || []
    return members
      .filter(member => member.isList
        && member.type === 'global'
        && member.typeName === typeName)
      .map(member=>member.name)
  },
})

export const editMemberProperty = selectorFamily({
  key: 'editMemberProperty',
  get: (path) => ({ get }) => {
    const member = get(CurrentMemberSelector)
    const elements = get(ElementsChangesAtom)
    if (!member) {
      return null
    }
    const currentElement = elements.find(el => el._id === member._id)
    if (currentElement) {
      if (path in currentElement) {
        return _.get(currentElement, path)
      }
    }
    return _.get(member, path)
  },
  set: (path) => ({
    get,
    set,
  }, newValue) => {
    const member = get(CurrentMemberSelector)
    const type = get(CurrentTypeSelector)
    window.typesWereChanged = true
    set(ElementsChangesAtom, produce((draft) => {
      const currentElement = draft.findIndex(el => el._id === member._id)
      if (currentElement === -1) {
        if (path === 'typeOfVariable') {
          draft.push({
            action: 'update',
            _id: member._id,
            kind: getKind(getMemberKey()),
            parent: type._id,
            originalType: member.type,
            parentKind: type.kind,
            [path]: newValue,
            options: '[]',
          })
          return
        }
        if (path === 'suggestion') {
          draft.push({
            action: 'update',
            _id: member._id,
            kind: getKind(getMemberKey()),
            parent: type._id,
            originalType: member.type,
            parentKind: type.kind,
            [path]: newValue,
            _options: '[]',
          })
          return
        }
        draft.push({
          action: 'update',
          _id: member._id,
          kind: getKind(getMemberKey()),
          parent: type._id,
          originalType: member.type,
          parentKind: type.kind,
          [path]: newValue,
        })
        return
      }
      _.set(draft[currentElement], path, newValue)

      if (path === 'typeOfVariable') {
        _.set(draft[currentElement], 'options', '[]')
      }
      if (path === 'suggestion') {
        _.set(draft[currentElement], '_options', '[]')
      }
    }))
  },
})

export const editMemberPropertyByPath = selector({
  key: 'editMemberPropertyByPath',
  get: () => null,
  set: (
    { set }, {
      path,
      value,
    }) => {
    set(editMemberProperty(path), value)
  },
})

export const ElementsChangesAtom = atom({
  key: 'ElementsChangesAtom',
  default: [],
})

export const NeedSaveElementsChanges = selector({
  key: 'NeedSaveElementsChanges',
  get: ({ get }) => {
    const elements = get(ElementsChangesAtom)
    return !!elements.length
  },
})

export const UserInfo = selector({
  key: 'UserInfo',
  get: async ({ get }) => {
    const { data: users } = await axios.get('/api/users')
    const Member = get(CurrentMemberSelector)
    if (Member.user) {
      const user = users.find(user => user._id === Member.user)
      if (user) {
        return user.name
      }
    }
    return ''
  },
})

export const VisibleElementModal = atom({
  key: 'VisibleElementModal',
  default: false,
})
