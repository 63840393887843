import React, { memo, useCallback } from 'react'
import Icon from '../Icon'
import classNames from 'classnames'
import { Dropdown, Menu } from 'antd'
import ModalConfirm from '../ModalConfirm'
import styled from 'styled-components'
import axios from 'axios'

import { FixedSizeList, areEqual } from 'react-window'
import AutoSizer from 'react-virtualized-auto-sizer'
import { useRouteMatch, useHistory } from 'react-router-dom'
import { useRecoilValue } from 'recoil'
import { getCurrentTab, SettingsTypesSelector } from '../../views/types/state'
import { OBJECT } from '../../config'
import getIsProduct from '../../utils/isProduct'

const ItemOfTypes = styled.li`
    color: ${(props) => props.theme.colorTable.text};
    background: ${(props) => props.theme.colorTable.plain};

    &:hover {
        background: ${(props) => props.theme.colorTable.backLightHover};
    }

    &.accent {
        color: ${(props) => props.theme.colorTable.listSelectionText};
        background: ${(props) => props.theme.colorTable.listSelection};
    }

    &:hover > .elements-option-menu {
        visibility: visible !important;
    }
`

const exportType = async (typeId, typeName) => {
  try {
    const { data } = await axios.get(`/api/types/${typeId}/export`,
      { responseType: 'blob' })
    const _url = window.URL.createObjectURL(data)
    const link = document.createElement('a')
    link.href = _url
    link.setAttribute('download', typeName)
    document.body.appendChild(link)
    link.click()
  } catch (e) {
    console.error(e.toString())
  }
}

const Item = memo(({
  index,
  style,
  data,
}) => {

  const item = data.items[index]

  const isProduct = getIsProduct(item)
  const goTo = () => {
    const CurrentTab = getCurrentTab()
    let nextPath = `/${window.tenancy}/types/${item.name}`

    if (CurrentTab !== 'variables') {
      if (item.type === OBJECT && CurrentTab !== 'apps') {
        nextPath = `/${window.tenancy}/types/${item.name}/${CurrentTab}`
      }
    }

    if (window.needSaveProfileTemplate) {
      return window.confirmProfile(nextPath)
    }

    return data.history.push(nextPath)
  }
  return (<ItemOfTypes style={style}
                       className={classNames(
                         'list-group-item rounded-0 d-flex justify-content-between cursorPointer',
                         {
                           'accent': !isProduct && item.name ===
                             data.activeTypename,
                         }, { 'disabled': isProduct })}
                       onClick={goTo}
  >
    <div className={'text-truncate'}>
      <Icon type={Icon.fromType(item.type)}
            className={'align-middle'}
            style={{ marginTop: -3 }}
      /> {item.name ? item.name : ''}
    </div>
    <div
      className={'elements-option-menu'}
      style={{ visibility: 'hidden' }}
      onClick={e => {
        e.preventDefault()
        e.stopPropagation()
      }}
    >
      <Dropdown overlay={<Menu>
        <Menu.Item key={'export' + item._id}
                   onClick={() => exportType(item._id, item.name + '.zip')}>
            <span><Icon type="icon-export"
                        style={{
                          fontSize: 14,
                          verticalAlign: '0.1em',
                        }}/><span> Export model</span></span>
        </Menu.Item>
        <Menu.Item key={'delete' + item._id}>
          <ModalConfirm
            title={`Are you sure you want to permanently delete the ${item.name} model?`}
            submit={() => {
              data.removeType(item)
            }}>
                  <span><Icon type="icon-trash"
                              style={{
                                fontSize: 14,
                                verticalAlign: '0.1em',
                              }}/><span> Delete model</span></span>
          </ModalConfirm>
        </Menu.Item>
      </Menu>}
      >
        <Icon type="icon-more" style={{
          fontSize: 18,
          verticalAlign: '0.1em',
        }}/>
      </Dropdown>
    </div>
  </ItemOfTypes>)
}, areEqual)

const List = ({
  items,
  removeType,
}) => {
  const match = useRouteMatch('/:tenancy/types/:typename')
  const {
    isClio,
    isFilevine,
  } = useRecoilValue(SettingsTypesSelector)
  const history = useHistory()

  const getTypename = () => {
    if (match === null) {
      if (items[0]) {
        return items[0].name
      }
    }
    if (match) {
      if (match.params) {
        if (match.params.typename) {
          return match.params.typename
        }
      }
      return null
    }
  }

  const activeTypename = getTypename()

  const measuredRef = useCallback(ref => {
    if (ref !== null) {
      const positionElement = items.findIndex(
        element => element.name === activeTypename)
      if (positionElement > -1) {
        ref.scrollToItem(positionElement, 'smart')
      }
    }
  }, [])
  return (<div style={{
    height: (isClio || isFilevine)
      ? 'calc(100vh - 13.25rem)'
      : 'calc(100vh - 11.25rem)',
  }}>
    <AutoSizer>
      {({
        height,
        width,
      }) => (<FixedSizeList
        ref={measuredRef}
        height={height}
        itemCount={items.length}
        itemSize={47}
        itemData={{
          items,
          removeType,
          activeTypename,
          history,
        }}
        width={width}
      >
        {Item}
      </FixedSizeList>)}
    </AutoSizer>
  </div>)
}

export default List
